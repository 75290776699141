import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Loading from "./pages/Loading";
import NotFound from "./pages/404";

////////////////////////////////////////////////////////////////////////////////
// Route List
// When you add a new page to this website, add its path here.
// Please *try* and keep this list sorted alphabetically by path.
const ROUTES = [
  ["/", "Home"],
  "/about-spark/fellowship",
  [
    "/about-spark/medical-affairs-team",
    () => import("./pages/AboutSpark/MedicalAffairsTeam")
  ],
  ["/contact-spark/:tabId?", () => import("./pages/ContactSpark")],
  ["/grants-and-investigator-support/:tabId?", "GrantsAndInvestigatorSupport"],
  ["/kiosk/mirf/:conferenceTag", () => import("./pages/ConferenceForm")],
  ["/medical-and-scientific-resources", () => import("./pages/Resources")],
  "/product-information",
  "/educational-resources",
  "/educational-resources/hematologic-and-lysosomal-disorders",
  "/educational-resources/inherited-retinal-diseases",
  "/educational-resources/neurodegenerative-diseases",

  process.env.NODE_ENV === "development" && "/style-guide"
].filter(x => x);
////////////////////////////////////////////////////////////////////////////////

export function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          {ROUTES.map(createRoute)}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

function createRoute(args) {
  let [path, componentNameOrLoader] = Array.isArray(args) ? args : [args];
  componentNameOrLoader = componentNameOrLoader || guessComponentName(path);

  // Code splitting
  // This import includes "./pages" as a hint to the code splitter
  // to package all pages in a single chunk. We could further
  // optimize this by using a separate `import` statement for each
  // component--we are trying to strike a balance between comprehensibility
  // of the set of routes and efficiency of the code splitter.
  const lazyLoader = React.lazy(
    typeof componentNameOrLoader === "function"
      ? componentNameOrLoader
      : () => import(`./pages/${componentNameOrLoader}`)
  );

  return <Route key={path} exact path={path} component={lazyLoader} />;

  function guessComponentName(path) {
    return path.replace(/^\//, "").replace(/(^|-|\/)([a-z])/g, uppercaseLetter);
  }

  function uppercaseLetter(match, boundary, letter) {
    return `${boundary === "/" ? boundary : ""}${letter.toUpperCase()}`;
  }
}
