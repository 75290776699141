import React, { useState, useEffect } from "react";
import { Modal } from "../Modal";

import { Button } from "../Button";
import { Link } from "../Link";
import { NoReactSnap } from "../NoReactSnap";

const LOCAL_STORAGE_KEY = "SMA_IsHcp";

export function HCPCheckModal({ children }) {
  const [isHcp, setIsHcp] = useState(getIsHcp());

  useEffect(() => {
    if (isHcp) {
      localStorage.setItem(LOCAL_STORAGE_KEY, isHcp);
    }
  }, [isHcp]);

  let modal = null;

  if (isHcp == null) {
    modal = <ConfirmHcpModal onConfirm={setIsHcp} />;
  } else if (isHcp === false) {
    modal = <NotAnHcpModal />;
  }

  return (
    <>
      <NoReactSnap>{modal}</NoReactSnap>
      {children}
    </>
  );
}

function getIsHcp() {
  const m = /(&|\?)hcp=(yes|no)(&|$)/.exec(window.location.search);

  if (m && m[2] === "yes") {
    return true;
  }

  if (m && m[2] === "no") {
    return false;
  }

  return localStorage.getItem(LOCAL_STORAGE_KEY);
}

function ConfirmHcpModal({ onConfirm }) {
  return (
    <Modal className="hcp-check-modal" canClose={false}>
      <h3 className="black">
        Please confirm you are a US healthcare professional.
      </h3>
      <p className="mb-3">
        This website is intended as an educational resource for US healthcare
        professionals only. It may include information about products that are
        not approved for use in the US or other countries. The information on
        this website is not intended as medical advice. By clicking "yes" below
        you are confirming that you are a US healthcare professional and have
        read and understood this information.
      </p>
      <p className="mb-3">
        I am a healthcare professional practicing in the US.
      </p>
      <div>
        <Button
          tiny
          primary
          onClick={() => onConfirm(true)}
          analytics={{
            category: "Access form",
            label: "Yes HCP"
          }}
        >
          Yes
        </Button>
        <Button
          tiny
          onClick={() => onConfirm(false)}
          analytics={{
            category: "Access form",
            label: "No HCP"
          }}
        >
          No
        </Button>
      </div>
    </Modal>
  );
}

function NotAnHcpModal() {
  return (
    <Modal className="not-an-hcp-modal" canClose={false}>
      <h3 className="black">
        This information is available only to US healthcare professionals.
      </h3>
      <p>
        If you would like to submit a medical question please click{" "}
        <Link noOffsiteWarning to="https://sparktx.com/medinfo/">
          here
        </Link>
        .
      </p>
      <Button tiny primary link="https://sparktx.com/" noOffsiteWarning>
        OK
      </Button>
    </Modal>
  );
}
