import React from "react";

import { Page } from "../components/Page";

/**
 * Page used for Loading state when navgating to a new page.
 */
export default function Loading() {
  return <Page title="Loading..." noTrackPageView />;
}
