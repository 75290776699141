import React from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";

const WARNING_FOR_SPARK_SITES = `
  Click "Continue" to leave this site and come back to visit us another time. Click "Cancel" to stay on sparkmedical.com.
`;

const WARNING_FOR_NON_SPARK_SITES = `
  Click "Continue" to leave this site. Spark is not responsible for the content
  of the site you are about to visit. Click "Cancel" to stay on
  sparkmedical.com.
`;

const SPARK_DOMAINS = [
  "sparktx.com",
  "sparkmedical.com",
  "sparkgenetherapyeducation.com"
];

/**
 * Modal displayed when the user clicks a link leading them offsite.
 * This is used directly by <Link>.
 * @param {object} props
 * @param {string} props.link - Link to send the user to.
 * @param {Function} props.onClose - Function called when the modal should be closed.
 * @param {string} props.rel - `rel` attribute for link
 * @param {string} props.target - `target` attribute for link
 *

 */
export function LeavingModal(props) {
  const { onClose, link, rel, target } = props;

  let copy = isLinkToSparkSite(link)
    ? WARNING_FOR_SPARK_SITES
    : WARNING_FOR_NON_SPARK_SITES;

  // When the user clicks the "Continue" button (link),
  // if we are opening in a new window, dismiss the modal as well.
  const handleLinkClick =
    target && onClose
      ? () => {
          onClose();
        }
      : undefined;

  return (
    <Modal className="leaving-site-modal" onClose={onClose}>
      <h3 className="black">
        You are about to leave sparkmedical.com, a Spark® Therapeutics website.
      </h3>
      <p>{copy}</p>
      <div className="right-aligned">
        <Button tiny onClick={onClose}>
          Cancel
        </Button>
        <Button
          link={link}
          noOffsiteWarning
          onClick={handleLinkClick}
          primary
          rel={rel}
          target={target}
          tiny
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
}

function isLinkToSparkSite(url) {
  const m = /^(https?:)?\/\/(www\.)?([^/]+)/.exec(url);
  if (!m) {
    // Assume link is to non-spark site
    return false;
  }
  const domain = m[3].toLowerCase();
  return SPARK_DOMAINS.indexOf(domain) >= 0;
}
