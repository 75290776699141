import React from "react";

import { SingleColumnLayout } from "../Grid";
import { Link } from "../Link";
import { Nav } from "../Nav";

import sparkLogoUrl from "./images/spark-logo.svg";
import sparkMedicalLogoUrl from "./images/spark-medical-logo.svg";

import "./siteheader.scss";

const NAV_ITEMS = [
  { text: "Home", url: "/" },
  {
    text: "Medical & Scientific Resources",
    url: "/medical-and-scientific-resources"
  },
  {
    text: "Educational Resources",
    items: [
      { text: "Overview", url: "/educational-resources" },
      {
        text: "Inherited Retinal Diseases",
        url: "/educational-resources/inherited-retinal-diseases"
      },
      {
        text: "Hematologic & Lysosomal Storage Disorders",
        url: "/educational-resources/hematologic-and-lysosomal-disorders"
      },
      {
        text: "Neurodegenerative Diseases",
        url: "/educational-resources/neurodegenerative-diseases"
      }
    ]
  },
  {
    text: "Grants & Investigator Support",
    url: "/grants-and-investigator-support"
  },
  {
    text: "About Us",
    items: [
      {
        text: "Medical Affairs Team",
        url: "/about-spark/medical-affairs-team"
      },
      { text: "Fellowship", url: "/about-spark/fellowship" }
    ]
  },
  { text: "Contact Us", url: "/contact-spark" }
];

export function SiteHeader({ kiosk }) {
  // Don't make the logo clickable in kiosk mode--contributes to
  // keeping the user on the kiosk page.
  const LogoWrapperElement = kiosk ? "span" : Link;
  const logoWrapperProps = {
    className: "site-header__logos__spark-medical",
    to: kiosk ? undefined : "/"
  };

  const showNav = !kiosk;

  return (
    <header className="site-header">
      <SingleColumnLayout>
        <div className="site-header__logos">
          <LogoWrapperElement {...logoWrapperProps}>
            <img alt="SparkMedical.com®" src={sparkMedicalLogoUrl} />
          </LogoWrapperElement>
          <span className="site-header__logos__tagline">
            Resources for US Healthcare Professionals
          </span>
          <img
            className="site-header__logos__spark"
            alt="Spark Therapeutics"
            src={sparkLogoUrl}
          />
        </div>
        {showNav && <Nav items={NAV_ITEMS} />}
      </SingleColumnLayout>
    </header>
  );
}
