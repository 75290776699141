import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Molecules } from "../Molecules";
import { ScrollTo } from "../ScrollTo";
import { SiteHeader } from "../SiteHeader";
import { SiteFooter } from "../SiteFooter";
import { HCPCheckModal } from "../HCPCheckModal";

import { dataLayerEvent } from "../../analytics";

import "./page.scss";

const DEFAULT_CAT_NUMBER = "N-SPK-US-700021-6";
const DEFAULT_COPYRIGHT_YEAR = 2024;

/**
 * Component for building a traditional "page" of content.
 * @param {object} props
 * @param {any} props.children - Page content.
 * @param {string} props.description - Page meta description
 * @param {bool} props.noindex - Whether to disable search indexing of the page.
 * @param {Array<string>?|string} props.molecules - How to adorn the page with decorative molecules
 * @param {string} props.title - Page title.
 * @param {string} props.subject - Page subject.
 * @param {string} props.className - class for page-specific styles.
 * @param {string} props.catNumber - Spark CAT number for page
 * @param {string} props.canonical - Canonical path for the page. Scheme + domain will be appended automatically.
 */
export function Page({
  className,
  title,
  description,
  children,
  molecules,
  noindex,
  catNumber,
  copyrightYear,
  noTrackPageView,
  canonical
}) {
  const meta = buildMeta(description, noindex);

  canonical = canonical
    ? ["https://sparkmedical.com", canonical.replace(/^\/+/, "")].join("/")
    : null;

  const link = [canonical && { rel: "canonical", href: canonical }].filter(
    x => x
  );

  const classes = ["page", className].filter(x => x).join(" ");

  useEffect(() => {
    if (!noTrackPageView) {
      dataLayerEvent("page_view", {
        page_title: title
      });
    }
  }, [noTrackPageView, title]);

  return (
    <HCPCheckModal>
      <Helmet title={title} meta={meta} link={link} />
      <ScrollTo>
        {applyMolecules(
          molecules,
          <div className={classes}>
            <SiteHeader />
            <main className="page__content">{children}</main>
          </div>
        )}
      </ScrollTo>
      <SiteFooter
        catNumber={catNumber || DEFAULT_CAT_NUMBER}
        copyrightYear={copyrightYear || DEFAULT_COPYRIGHT_YEAR}
      />
    </HCPCheckModal>
  );
}

/**
 * Component for building a page without links
 * @param {object} props
 */
export function KioskPage({
  className,
  title,
  description,
  children,
  molecules,
  noindex,
  catNumber,
  copyrightYear,
  noTrackPageView
}) {
  const meta = buildMeta(description, noindex);
  const classes = ["page", "kiosk", className].filter(x => x).join(" ");

  useEffect(() => {
    if (!noTrackPageView) {
      dataLayerEvent("page_view", {
        page_title: title
      });
    }
  }, [noTrackPageView, title]);

  return (
    <HCPCheckModal>
      <Helmet title={title} meta={meta} />
      {applyMolecules(
        molecules,
        <div className={classes}>
          <SiteHeader kiosk />
          <main className="page__content">{children}</main>
        </div>
      )}
      <SiteFooter
        catNumber={catNumber || DEFAULT_CAT_NUMBER}
        copyrightYear={copyrightYear || DEFAULT_COPYRIGHT_YEAR}
        kiosk
      />
    </HCPCheckModal>
  );
}

function buildMeta(description, noindex) {
  // NOTE: We use `title` and `meta` props with react-helmet (rather than
  //       <title> and <meta> children) to work around incompatibilities with
  //       the useEffect hook.
  //       More info: https://github.com/nfl/react-helmet/issues/373
  return [
    description && { name: "description", content: description },
    noindex && { name: "robots", content: "noindex" }
  ].filter(x => x);
}

function applyMolecules(positions, content) {
  let result = content;

  positions = Array.isArray(positions) ? positions : [positions];

  positions.forEach(pos => {
    if (pos != null) {
      result = <Molecules position={pos}>{result}</Molecules>;
    }
  });

  return result;
}
