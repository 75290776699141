import React, { useEffect, useRef } from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";

export function ScrollTo({ center, children, enabled = true, smooth }) {
  const el = useRef(null);
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const isReactSnap = navigator.userAgent === "ReactSnap";

    if (el.current && !isReactSnap) {
      scrollIntoView(el.current, {
        behavior: smooth ? "smooth" : "auto",
        block: center ? "center" : "start"
      });
    }
  }, [center, enabled, smooth]);

  if (!children) {
    return children;
  }

  return React.cloneElement(children, { ref: el });
}
