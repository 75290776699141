import React from "react";
import * as Flexbox from "react-flexbox-grid";

import "./grid.scss";

export function Grid(props) {
  // Default Grids to "fluid"
  const gridProps = props.fluid == null ? { ...props, fluid: true } : props;

  return <Flexbox.Grid {...gridProps} />;
}

export const Row = Flexbox.Row;
export const Col = Flexbox.Col;

/**
 * A basic single-column layout. Provided as a shortcut to reduce the
 * amount of nesting required.
 */
export function SingleColumnLayout({ children, ...rest }) {
  return (
    <Grid {...rest}>
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </Grid>
  );
}

export function FiftyFiftyLayout({ children, ...rest }) {
  let firstChild = null,
    secondChild = null;

  firstChild = Array.isArray(children) ? children[0] : children;
  secondChild = Array.isArray(children) ? children.slice(1) : null;

  return (
    <Grid {...rest}>
      <Row>
        <Col xs={12} md={6}>
          {firstChild}
        </Col>
        <Col xs={12} md={6}>
          {secondChild}
        </Col>
      </Row>
    </Grid>
  );
}
