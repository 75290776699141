import React from "react";

import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Grid, Row, Col } from "../components/Grid";
import { Page } from "../components/Page";

export default function NotFound() {
  return (
    <Page
      title="SparkMedical.com® | 404 Error"
      molecules="bottom-right"
      noindex
    >
      <section>
        <Grid fluid>
          <Row className="pt-2">
            <Col xs={12} md={6}>
              <h2>We're Sorry.</h2>
              <p>The page you’re looking for could not be found.</p>
              <Button small primary link="/">
                SparkMedical.com Home
              </Button>
            </Col>
            <Col
              xs={12}
              md={5}
              mdOffset={1}
              className="pt-1-sm pb-1-sm pt-1-xs pb-1-xs"
            >
              <Card small title="Give us a call.">
                <p>
                  If you have an inquiry about a Spark product or therapeutic
                  area, give us a call.
                </p>
                <p>
                  <span className="nowrap">1-866-MISPARK</span> /{" "}
                  <span className="nowrap">1-866-647-7275</span> <br />
                  <span className="nowrap">M-F 8am-8pm</span> ET
                </p>
              </Card>
            </Col>
          </Row>
        </Grid>
      </section>
    </Page>
  );
}
