import React from "react";

import "./molecules.scss";

export const Molecules = React.forwardRef(({ children, position }, ref) => {
  const classes = ["molecules", position && `molecules--${position}`]
    .filter(x => x)
    .join(" ");

  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  );
});
