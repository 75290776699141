import { useEffect, useRef, useState } from "react";

const BREAKPOINTS = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200
};

/**
 * Given an object, constructs a querystring out of its keys + values.
 * @param {Object} values
 */
export function buildQueryString(values) {
  return Object.keys(values)
    .map(
      key =>
        `${encodeURIComponent(key)}=${
          values[key] == null ? "" : encodeURIComponent(values[key])
        }`
    )
    .join("&");
}

/**
 * Returns whether the page is currently being served to react-snap.
 */
export function isReactSnap() {
  try {
    return navigator.userAgent === "ReactSnap";
  } catch (err) {
    return false;
  }
}

/**
 *
 * @param {"xs"|"sm"|"md"|"lg"} breakpoint
 */
export function useBreakpoint(breakpoint) {
  const [isActive, setIsActive] = useState(checkBreakpointActive(breakpoint));
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    function handleResize() {
      setIsActive(checkBreakpointActive(breakpoint));
    }
  }, [breakpoint]);

  return isActive;
}

export function useDebouncedEffect(fn, inputs, delay = 100) {
  const timer = useRef(null);
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(fn, delay);
    return () => clearTimeout(timer.current);
  }, [...inputs, delay]); // eslint-disable-line
}

function checkBreakpointActive(breakpoint) {
  return window.innerWidth <= BREAKPOINTS[breakpoint];
}
