import React, { useEffect, useState } from "react";

import { Link } from "../Link";

import { ReactComponent as ChevronIcon } from "./images/chevron.svg";
import { ReactComponent as HamburgerIcon } from "./images/hamburger.svg";
import { ReactComponent as CloseIcon } from "./images/close.svg";

import "./nav.scss";

/**
 * Responsive navigation.
 * @param {object} props
 */
export function Nav({ items, visibleByDefault, subNavVisibleByDefault }) {
  const [visible, setVisible] = useState(visibleByDefault);

  usePreventScroll(visible);

  const classes = ["nav", visible && "nav--visible"].filter(x => x).join(" ");

  return (
    <>
      <button
        aria-label="Menu"
        className="nav__open-button"
        onClick={() => setVisible(true)}
      >
        <HamburgerIcon />
      </button>
      <nav className={classes} role="navigation">
        <button
          aria-label="Close Menu"
          className="nav__close-button"
          onClick={() => setVisible(false)}
        >
          <CloseIcon />
        </button>
        <NavList
          items={items}
          level={1}
          subNavVisible={subNavVisibleByDefault}
          onNavigate={() => setVisible(false)}
        />
      </nav>
    </>
  );
}

export function NavItem({
  active,
  items,
  level,
  onNavigate,
  subNavVisible: subNavVisibleByDefault,
  text,
  url
}) {
  const [subNavVisible, setSubNavVisible] = useState(subNavVisibleByDefault);

  if (!url && items) {
    url = items[0].url;
  }

  // TODO: Use react-router-dom to do this calculation
  if (active == null) {
    active =
      isCurrentPage(url) ||
      (items && items.some(item => isCurrentPage(item.url)));
  }

  const classes = [
    "nav__item",
    `nav__item--l${level}`,
    items && "nav__item--has-subnav",
    items && subNavVisible && "nav__item--subnav-visible",
    active && "nav__item--is-active",
    active && `nav__item--l${level}--is-active`
  ]
    .filter(x => x)
    .join(" ");

  const linkClasses = [
    "nav__link",
    `nav__link--l${level}`,
    active && "nav__link--is-active",
    active && `nav__link--l${level}--is-active`
  ]
    .filter(x => x)
    .join(" ");

  let chevron = null;

  if (items) {
    chevron = <ChevronIcon className="nav__link__chevron" />;
  }

  return (
    <li className={classes}>
      <Link className={linkClasses} to={url} onClick={handleClick}>
        {text}
        {chevron}
      </Link>
      {items && (
        <NavList items={items} level={level + 1} visible={subNavVisible} />
      )}
    </li>
  );

  function handleClick(evt) {
    if (items && items.length) {
      evt.preventDefault();
      setSubNavVisible(!subNavVisible);
      return;
    }
    onNavigate && onNavigate();
  }
}

export function NavList({ items, level, onNavigate, subNavVisible }) {
  level = level == null ? 0 : level;

  const classes = ["nav__list", `nav__list--l${level}`]
    .filter(x => x)
    .join(" ");

  return (
    <ul className={classes}>
      {items.map((item, index) => (
        <NavItem
          key={index}
          {...item}
          level={level}
          onNavigate={onNavigate}
          subNavVisible={subNavVisible}
        />
      ))}
    </ul>
  );
}

function usePreventScroll(prevent) {
  useEffect(() => {
    if (!prevent) {
      return;
    }

    // Prevent scrolling on mobile (for example, while mobile nav is open.)
    // NOTE: this technique will reset scroll position, and won't work with
    //       sticky navs.

    const html = document.documentElement;
    const body = document.body;

    html.style.position = body.style.position = "fixed";
    html.style.overflow = body.style.overflow = "hidden";

    return () => {
      html.style.overflow = body.style.overflow = "";
      html.style.position = body.style.position = "";
    };
  }, [prevent]);
}

function isCurrentPage(url) {
  if (url === "/") {
    return window.location.pathname === url;
  }

  return window.location.pathname.substring(0, url.length) === url;
}
