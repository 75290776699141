/**
 * Action assigned to analytics events generated from clicks (by default).
 */
function dataLayerPush(...args) {
  if (
    typeof window.dataLayer === "object" &&
    typeof window.dataLayer.push === "function"
  ) {
    window.dataLayer.push(...args);
  }
}

export function dataLayerEvent(eventType, eventParameters) {
  dataLayerPush({
    event: eventType,
    event_params: {
      ...eventParameters,
      page_location: window.location.href,
      page_path: window.location.pathname
    }
  });
}
