import React from "react";

import "./tel.scss";
export function Tel(props) {
  const { className, href, label } = props;

  return (
    <a href={href} className={className}>
      {label}
    </a>
  );
}
