import { isReactSnap } from "../utils";

/**
 * Component that does not render its children if
 * the page is being viewed via react-snap.
 * This can be used to disable functionality that breaks when rehydrating
 * from react-snap renders (like modals).
 */
export function NoReactSnap({ children, fallback = false }) {
  return isReactSnap() ? fallback : children;
}
