import React from "react";

import "./hr.scss";

export function Hr({ color, hairline, skinny, center }) {
  const classes = [
    "hr",
    color && `hr--color-${color}`,
    hairline && "hr--hairline",
    skinny && "hr--skinny",
    center && "hr--center"
  ]
    .filter(x => x)
    .join(" ");
  return <hr className={classes} />;
}
