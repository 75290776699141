import React from "react";
import { Dialog } from "@reach/dialog";

import "./modal.scss";

const NOOP = () => {};

/**
 * Modal component, based on @reach/dialog.
 * Modals are displayed when they are rendered -- to prevent the display of a
 * modal, just don't render it.
 */
export function Modal({
  canClose = true,
  children,
  className,
  onClose,
  skinny,
  ...rest
}) {
  const classes = ["modal", className, skinny && "modal--skinny"]
    .filter(x => x)
    .join(" ");

  const handleClose = canClose ? onClose || NOOP : NOOP;

  return (
    <Dialog className={classes} isOpen={true} onDismiss={handleClose} {...rest}>
      {canClose && <button className="modal__close" onClick={handleClose} />}
      {children}
    </Dialog>
  );
}
