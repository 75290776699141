import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate, render } from "react-dom";
import { App } from "./App";

import "./style.scss";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// In development, do an /api smoke test
if (process.env.NODE_ENV === "development") {
  fetch("/api/health").then(resp => {
    if (resp.status !== 200) {
      console.warn(
        "Received %d from /api/health--check that API is working.",
        resp.status
      );
    }
  });
}
